import * as React from 'react';
import axios from "axios";
import { Prefectures, PrefectureValue } from "../types/Prefecture";
import { UserAddress } from "../types/UserAddress";
import AddressFields from "../profile_fields/AddressFields";
import TelField from "../profile_fields/TelField";

type Props = {
  userAddresses: UserAddress[],
  prefectures: Prefectures,
  selectablePrefectures: Prefectures,
  addressUid: string,
  setAddressUid: (value: string) => void,
  newAddressRecipient: string,
  newAddressZip: string,
  newAddressPrefecture: PrefectureValue,
  newAddressCity: string,
  newAddressAddress1: string,
  newAddressAddress2: string,
  newAddressTel: string,
  setNewAddressRecipient: (value: string) => void,
  setNewAddressZip: (value: string) => void,
  setNewAddressPrefecture: (value: string) => void,
  setNewAddressCity: (value: string) => void,
  setNewAddressAddress1: (value: string) => void,
  setNewAddressAddress2: (value: string) => void,
  setNewAddressTel: (value: string) => void,
  isRecipientInvalid: boolean,
  isZipInvalid: boolean,
  isPrefectureInvalid: boolean,
  isCityInvalid: boolean,
  isAddress1Invalid: boolean,
  isAddress2Invalid: boolean,
  isTelInvalid: boolean,
  isShippingAddressEdit: boolean,
  holdAddressCache: (value: string) => void,
  addressCacheType: string,
};

class NewOrderAddressFields extends React.Component<Props> {
  constructor (props: Props) {
    super(props);

    this.translatePrefectureValue = this.translatePrefectureValue.bind(this);
  }

  translatePrefectureValue(prefectureValue: PrefectureValue): string|null {
    const { prefectures } = this.props;
    const prefecture = prefectures.find(pref => pref[1] === prefectureValue);
    return(prefecture ? prefecture[0] : null);
  }

  render() {
    const {
      userAddresses, addressUid, setAddressUid, selectablePrefectures,
      newAddressRecipient, newAddressZip, newAddressPrefecture,
      newAddressCity, newAddressAddress1, newAddressAddress2,
      setNewAddressRecipient, setNewAddressZip, setNewAddressPrefecture,
      setNewAddressCity, setNewAddressAddress1, setNewAddressAddress2,
      newAddressTel, setNewAddressTel,
      isRecipientInvalid, isTelInvalid, isZipInvalid, isPrefectureInvalid,
      isCityInvalid, isAddress1Invalid, isAddress2Invalid, isShippingAddressEdit,
      holdAddressCache, addressCacheType,
    } = this.props;
    const selectedUserAddress = userAddresses.find(ua => ua.uid === addressUid);

    const isPrefectureSelectable = selectedUserAddress && selectablePrefectures.some(
      pref => pref[1] === selectedUserAddress.prefecture
    );

    return(
      <>
        <div className="row mb-3">
          <div className="col-sm-4">
            <select
              className="form-select"
              value={addressUid}
              onChange={e => setAddressUid(e.target.value)}
            >
              <option value="">新しい住所を入力</option>
              {userAddresses.map(ua => <option key={ua.uid} value={ua.uid}>
                {ua.recipient}様（{this.translatePrefectureValue(ua.prefecture)}）
              </option>)}
            </select>
          </div>
        </div>

        {selectedUserAddress && <>
          {!isPrefectureSelectable && <div className="alert alert-danger mt-3">
            <i className="fas fa-exclamation-triangle"></i>
            ご注文の商品は、<b>{this.translatePrefectureValue(selectedUserAddress.prefecture)}</b>には配送することができません。
          </div>}
          <div className="p-checkout_recipient">
            <div className="p-checkout_recipient_name">{selectedUserAddress.recipient}様</div>
            {selectedUserAddress.fullAddress}
          </div>
        </>}

        {!selectedUserAddress && <>
          <div className="form-group row mb-3">
            <div className="col-sm-6">
              <label>{isShippingAddressEdit ? 'ご注文者名' : 'お届け先氏名'}<span className="c-form_rq">必須</span></label>
              <input type="text"
                value={newAddressRecipient}
                onChange={e => setNewAddressRecipient(e.target.value)}
                onBlur={e => holdAddressCache(addressCacheType)}
                placeholder="OMAKASE 太郎"
                className={"form-control" + (isRecipientInvalid ? " is-invalid" : "")}
              />
            </div>
          </div>
          <AddressFields
            zip={newAddressZip}
            prefecture={newAddressPrefecture}
            city={newAddressCity}
            address1={newAddressAddress1}
            address2={newAddressAddress2}
            setZip={setNewAddressZip}
            setPrefecture={setNewAddressPrefecture}
            setCity={setNewAddressCity}
            setAddress1={setNewAddressAddress1}
            setAddress2={setNewAddressAddress2}
            isZipInvalid={isZipInvalid}
            isPrefectureInvalid={isPrefectureInvalid}
            isCityInvalid={isCityInvalid}
            isAddress1Invalid={isAddress1Invalid}
            isAddress2Invalid={isAddress2Invalid}
            prefectures={selectablePrefectures}
            holdAddressCache={holdAddressCache}
            addressCacheType={addressCacheType}
          />
          <TelField
            tel={newAddressTel}
            setTel={setNewAddressTel}
            isTelInvalid={isTelInvalid}
            holdAddressCache={holdAddressCache}
            addressCacheType={addressCacheType}
          />
        </>}
      </>
    );
  }
};

export default NewOrderAddressFields;
